import { REGEX } from '_common/constants/common';
import { PAYMENT_METHOD_TYPE } from '_common/constants/invoiceManagement';
import { messageValidate } from '_common/constants/message';
import Image from '_common/dof/Control/Image';
import Input from '_common/dof/Control/Input';
import Toggle from '_common/dof/Control/Switch';
import TextArea from '_common/dof/Control/TextArea';
import { FormTeraItem } from '_common/dof/FormTera';
import SelectBank from '_common/dof/Select/SelectBank';
import _ from 'lodash';
import { UseFormReturn } from 'react-hook-form';
import { Fragment } from 'react/jsx-runtime';

interface IFormCreateUpdateProps {
  form: UseFormReturn<any>;
}

const FormCreateUpdate = ({ form }: IFormCreateUpdateProps) => {
  return (
    <Fragment>
      <FormTeraItem
        name="type_id"
        label={
          _.isEqual(form.watch('method_type'), PAYMENT_METHOD_TYPE.BANK)
            ? 'Ngân hàng'
            : 'Ví điện tử'
        }
        rules={[{ required: messageValidate.emptySelect }]}
      >
        <SelectBank
          paramsApi={{
            type: form.watch('method_type'),
            include_id: form.watch('type_id'),
          }}
          onChangeCustom={(value) => form.setValue('type', value)}
        />
      </FormTeraItem>
      <FormTeraItem
        name="account_number"
        label={
          _.isEqual(form.watch('method_type'), PAYMENT_METHOD_TYPE.BANK)
            ? 'Số tài khoản'
            : 'Số điện thoại'
        }
        rules={[
          {
            required: messageValidate.emptyText,
            pattern: _.isEqual(
              form.watch('method_type'),
              PAYMENT_METHOD_TYPE.BANK,
            )
              ? {
                  value: new RegExp(REGEX.CARD_NUMBER),
                  message: messageValidate.format,
                }
              : {
                  value: new RegExp(REGEX.PHONE_NUMBER),
                  message: messageValidate.phone,
                },
          },
        ]}
      >
        <Input maxLength={20} />
      </FormTeraItem>
      <FormTeraItem
        name="account_name"
        label="Người thụ hưởng"
        rules={[
          {
            required: messageValidate.emptyText,
            maxLength: {
              value: 50,
              message: 'Không nhập quá 50 kí tự',
            },
          },
        ]}
      >
        <Input className="uppercase placeholder-shown:normal-case" />
      </FormTeraItem>
      <FormTeraItem name="description" label="Nội dung">
        <TextArea rows={5} maxLength={1000} />
      </FormTeraItem>
      <FormTeraItem
        label="QR code"
        className="flex flex-col col-span-2"
        name="qr_code"
      >
        <Image folder="payment-method" object_key="payment-method" />
      </FormTeraItem>
      <FormTeraItem label="Đặt là mặc định" name="is_default">
        <Toggle />
      </FormTeraItem>
    </Fragment>
  );
};

export default FormCreateUpdate;

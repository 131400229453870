import { useQuery } from '@tanstack/react-query';
import CardDetail from '_common/component/CardDetail';
import FormCustomer from '_common/component/Form/Customer';
import FormPromoCode from '_common/component/Form/PromoCode';
import NoData from '_common/component/NoData';
import {
  PRICE_PERIOD_TEXT,
  SERVICE,
} from '_common/constants/serviceManagement';
import Select from '_common/dof/Control/Select';
import { FormTeraItem } from '_common/dof/FormTera';
import _ from 'lodash';
import ModalAddCloudVPS from 'pages/ServiceManagement/CloudVPS/modals/ModalAddCloudVPS';
import DatacenterAPI from 'pages/ServicePackage/Datacenter/apis';
import { useEffect, useMemo, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { Fragment } from 'react/jsx-runtime';
import { Swiper, SwiperSlide } from 'swiper/react';
import customTwMerge from 'tailwind-merge.config';
import { Row, Spin, formatCurrency } from 'tera-dls';

interface IFromCreateUpdateProps {
  form: UseFormReturn<any>;
  countryCode: string;
  dataDetail?: any;
}

const FormCreateUpdate = ({
  form,
  countryCode,
  dataDetail,
}: IFromCreateUpdateProps): JSX.Element => {
  const [isOpenModalAddCloudVPS, setIsOpenModalAddCloudVPS] =
    useState<boolean>(false);

  const {
    data: dataDatacenterProduct,
    refetch: refetchDataDatacenterProduct,
    isLoading: isLoadingDataDatacenterProduct,
    isError: isErrorDataDatacenterProduct,
  } = useQuery(
    ['get-service-package-datacenter-product-list', countryCode],
    () => {
      const params = {
        country: countryCode,
        planType: SERVICE.CLOUD_VPS,
      };
      return DatacenterAPI.getProductList({ params });
    },
    {
      enabled: !_.isNil(countryCode),
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    if (countryCode) refetchDataDatacenterProduct();
    else if (_.isNil(dataDetail?.id)) setIsOpenModalAddCloudVPS(true);
  }, [countryCode]);

  useEffect(() => {
    if (_.isEmpty(dataDetail) && !_.isEmpty(dataDatacenterProduct?.data)) {
      const product = dataDatacenterProduct?.data[0].products[0]?.product;

      form.reset({
        ...form.getValues(),
        datacenter: dataDatacenterProduct?.data[0],
        product: product,
        locationId: product?.locations[0].locationId,
        osId: product?.operating_systems[0].osId,
      });
    }
  }, [dataDetail, dataDatacenterProduct?.data]);

  const pricePeriod = useMemo(
    () => PRICE_PERIOD_TEXT[form.watch('period')],
    [form.getValues()],
  );

  const datacenter = useMemo(
    () =>
      dataDatacenterProduct?.data.find(
        (datacenterProduct) =>
          datacenterProduct.datacenterId ===
          form.watch('datacenter').datacenterId,
      ),
    [dataDatacenterProduct?.data, form.getValues()],
  );

  const ProductOptions = useMemo(
    () => datacenter?.products,
    [datacenter, form.getValues()],
  );

  const product = useMemo(
    () =>
      ProductOptions?.find(
        (product) => product.planId === form.watch('product').planId,
      )?.product,
    [ProductOptions, form.getValues()],
  );

  const LocationOptions = useMemo(
    () =>
      product?.locations?.map(({ location }) => ({
        value: location.locationId,
        label: location.locationName,
      })),
    [product, form.getValues()],
  );

  const OSOptions = useMemo(
    () =>
      product?.operating_systems?.map(({ os }) => ({
        value: os.osId,
        label: os.osName,
      })),
    [product, form.getValues()],
  );

  const handleSelectDatacenterProduct = (datacenterProduct: any) => {
    const product = datacenterProduct.products[0]?.product;

    form.reset(
      {
        ...form.getValues(),
        datacenter: datacenterProduct,
        product: product,
        locationId: product?.locations[0].locationId,
        osId: product?.operating_systems[0].osId,
      },
      { keepDirty: true },
    );
  };

  const handleSelectProduct = (product: any) => {
    form.reset(
      {
        ...form.getValues(),
        product: product,
        locationId: product.locations[0].locationId,
        osId: product.operating_systems[0].osId,
      },
      { keepDirty: true },
    );
  };

  const formSections = [
    {
      name: 'Chọn Datacenter',
      content: () => (
        <Swiper
          spaceBetween={25}
          slidesPerView={3}
          className="!w-full !h-auto !p-4 !pt-0"
        >
          {dataDatacenterProduct?.data.map((datacenterProduct: any) => (
            <SwiperSlide
              key={datacenterProduct.datacenterId}
              onClick={() => handleSelectDatacenterProduct(datacenterProduct)}
              className={customTwMerge(
                'min-w-[400px] max-w-[400px] !h-auto bg-white p-6 py-8 rounded-[8px] hover:drop-shadow-xl flex flex-col justify-between cursor-pointer',
                _.isEqual(
                  form.watch('datacenter')?.datacenterId,
                  datacenterProduct?.datacenterId,
                ) && 'text-blue-800 !bg-[#EBF5FF] drop-shadow-xl',
              )}
            >
              <span className="text-[30px] font-bold leading-9">
                {datacenterProduct.datacenterName}
              </span>
            </SwiperSlide>
          ))}
        </Swiper>
      ),
    },
    {
      name: 'Chọn gói dịch vụ',
      content: () => (
        <Row className="grid-cols-4 gap-x-8 gap-y-5">
          {ProductOptions?.map(({ product }) => {
            const details = [
              {
                label: 'Dung lượng',
                value: product.config.ssd,
              },
              {
                label: 'Ram',
                value: product.config.ram,
              },
              {
                label: 'CPU',
                value: product.config.cpu,
              },
              { label: 'Backup', value: product.backup },
              {
                label: 'Bandwidth',
                value: product.config.bandwidth,
              },
            ];

            return (
              <div
                key={product.planId}
                className={customTwMerge(
                  '!h-auto bg-white p-6 py-8 rounded-[8px] hover:drop-shadow-xl flex flex-col cursor-pointer',
                  _.isEqual(form.watch('product').planId, product.planId) &&
                    'text-blue-800 !bg-[#EBF5FF] drop-shadow-xl',
                )}
                onClick={() => handleSelectProduct(product)}
              >
                <span className="text-[30px] font-bold leading-9">
                  {product.planCode}
                </span>
                <div className="pb-8 mt-[4rem] border-b-[1px] border-[#606999] border-dashed flex gap-2">
                  <span className="text-[25px] font-bold">
                    {formatCurrency(product.prices[form.watch('period')])}
                  </span>
                  <span>/</span>
                  <span>{pricePeriod}</span>
                </div>
                <div className="mt-[2rem] flex flex-col items-center gap-4">
                  {details.map((detail, index) => (
                    <div key={index} className="w-full flex gap-3">
                      <span className="flex-1 text-gray-500 text-right">
                        {detail.label}
                      </span>
                      <span className="flex-1 font-[500]">{detail.value}</span>
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
        </Row>
      ),
    },
    {
      name: 'Vị trí',
      content: () => (
        <CardDetail className="p-4">
          <FormTeraItem name="locationId" className="mb-0">
            <Select options={LocationOptions} />
          </FormTeraItem>
        </CardDetail>
      ),
    },
    {
      name: 'OS Template',
      content: () => (
        <CardDetail className="p-4">
          <FormTeraItem name="osId" className="mb-0">
            <Select options={OSOptions} />
          </FormTeraItem>
        </CardDetail>
      ),
    },
    {
      name: 'Thông tin người dùng',
      content: () => <FormCustomer form={form} />,
    },
    {
      name: 'Sử dụng mã khuyến mãi',
      content: () => <FormPromoCode />,
    },
  ];

  return (
    <Spin spinning={isLoadingDataDatacenterProduct}>
      {isErrorDataDatacenterProduct ||
      _.isEmpty(dataDatacenterProduct?.data) ? (
        <NoData />
      ) : (
        <div className="p-2 flex flex-col gap-10">
          {formSections.map((section, index) => (
            <Fragment key={index}>
              <div className="flex items-center gap-2">
                <span className="w-[30px] h-[30px] text-blue-500 font-[500] border-[1px] border-blue-500 rounded-[30px] flex justify-center items-center">
                  {index + 1}
                </span>
                <span className="text-[20px] font-[500]">{section.name}</span>
              </div>
              {section.content()}
            </Fragment>
          ))}
        </div>
      )}
      {isOpenModalAddCloudVPS && (
        <ModalAddCloudVPS
          open={isOpenModalAddCloudVPS}
          close={() => setIsOpenModalAddCloudVPS(false)}
        />
      )}
    </Spin>
  );
};

export default FormCreateUpdate;

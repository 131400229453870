import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const PaymentMethodAPIEndpoint = `${endpoint}/admin/finance/payment-method`;
const PaymentMethodPortalAPIEndpoint = `${endpoint}/portal/finance/payment-method`;

const PaymentMethodAPI = {
  getList: async ({ params }) =>
    await api
      .get(`${PaymentMethodAPIEndpoint}/list`, params)
      .then((res) => res.data?.data),
  getListBank: async ({ params }) =>
    await api
      .get(`${PaymentMethodPortalAPIEndpoint}/listBank`, params)
      .then((res) => res.data?.data),
  getDetail: async ({ id }) =>
    await api
      .get(`${PaymentMethodAPIEndpoint}/detail/${id}`)
      .then((res) => res.data?.data),
  getVietQR: async ({ id, params }) =>
    await api
      .post(`${PaymentMethodPortalAPIEndpoint}/getVietQR/${id}`, params)
      .then((res) => res.data?.data),
  create: async ({ params }) =>
    await api
      .post(`${PaymentMethodAPIEndpoint}/create`, params)
      .then((res) => res.data),
  update: async ({ id, params }) =>
    await api
      .put(`${PaymentMethodAPIEndpoint}/update/${id}`, params)
      .then((res) => res.data),
  delete: async ({ id }) =>
    await api
      .delete(`${PaymentMethodAPIEndpoint}/delete/${id}`)
      .then((res) => res.data),
};

export default PaymentMethodAPI;

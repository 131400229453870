import { useQuery } from '@tanstack/react-query';
import DefaultImage from '_common/component/DefaultImage';
import { TParamsApiDof } from '_common/dof/interfaces';
import { useDebounce } from '_common/hooks/useDebounce';
import PaymentMethodAPI from 'pages/Finance/PaymentManagement/PaymentMethod/apis';
import { forwardRef, useMemo, useState } from 'react';
import { OptionProps } from 'tera-dls';
import { SelectProps } from 'tera-dls/lib/components/Select';
import SelectEntity from '../SelectEntity';

interface SelectBankProps extends SelectProps {
  paramsApi?: TParamsApiDof;
}

const SelectBank = forwardRef<HTMLInputElement, SelectBankProps>(
  ({ placeholder = 'Vui lòng chọn', paramsApi, ...props }, ref) => {
    const [search, setSearch] = useState<string>('');
    const searchDebounce = useDebounce(search, 300);
    setSearch;
    const { data: bankList, isLoading } = useQuery(
      ['get-bank-list', searchDebounce, paramsApi],
      () => {
        const params = {
          page: 1,
          limit: 100,
          keyword: searchDebounce,
          ...paramsApi,
        };
        return PaymentMethodAPI.getListBank({ params });
      },
      {
        staleTime: 300000,
        cacheTime: 300000,
      },
    );

    const options: OptionProps[] = useMemo(() => {
      if (!bankList) return [];

      const data = bankList?.data?.map((bank) => ({
        label: bank?.name,
        value: bank?.id,
        labelDisplay: (
          <div className="flex items-center gap-x-2.5">
            <DefaultImage
              className="w-8 h-8 rounded"
              src={bank?.image_bank}
              alt={bank?.name}
            />
            <div className="flex flex-col">
              <p>{bank?.name}</p>
              <p>{bank?.description}</p>
            </div>
          </div>
        ),
      }));
      return data;
    }, [bankList]);

    return (
      <SelectEntity
        ref={ref}
        allowClear
        showSearch
        labelInValue
        loading={isLoading}
        placeholder={placeholder}
        options={options}
        searchValue={search}
        onSearch={setSearch}
        {...props}
      />
    );
  },
);

export default SelectBank;
